<script setup lang="ts">
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from "@headlessui/vue";

interface Props {
  show: boolean;
  maxWidth?: string;
  width?: string;
  closeable?: boolean;
  showCloseX?: boolean;
  overflow?: string;
}

interface Emits {
  (e: "close"): void;
}

const props = withDefaults(defineProps<Props>(), {
  maxWidth: "max-w-xl",
  width: "w-full",
  overflow: "overflow-show",
  closeable: true,
});

const emit = defineEmits<Emits>();

const close = () => {
  if (props.closeable) emit("close");
};
</script>

<template>
  <TransitionRoot appear :show="show" as="template">
    <Dialog as="div" @close="close" class="relative z-[40]">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              :class="[maxWidth, width, overflow]"
              class="relative transform rounded-md bg-white text-left align-middle shadow-xl transition-all"
            >
              <div
                v-if="showCloseX"
                class="absolute -top-2 -right-2 sm:-top-1 sm:-right-1 bg-white rounded-full h-6 w-6 flex items-center justify-center p-1 shadow-md"
              >
                <span class="sr-only">Close</span>
                <Icon
                  name="fa6-solid:xmark"
                  class="w-5 h-5 text-medium-gray hover:text-primary cursor-pointer transition-all hover:rotate-180"
                  @click="close"
                />
              </div>

              <slot />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
